
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import BackgroundImage from '~/components/_general/BackgroundImage.vue'

export default Vue.extend({
  name: 'SiteImageHeader',
  components: { BackgroundImage },
  props: {
    title: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    subtitle: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
    backgroundSrc: {
      type: String,
      default:
        'https://media.sermonaudio.com/images/3/web/images/overview-header-default.jpg',
    },
    backgroundColorHex: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    bgColorClass(): string {
      return this.backgroundColorHex ? '' : 'bg-blue'
    },
    bgColorHexStyle(): string {
      if (!this.backgroundColorHex) return ''
      return `background-color: ${this.backgroundColorHex}`
    },
  },
  head() {
    return {
      link: [
        {
          rel: 'preload',
          as: 'image',
          href: this.backgroundSrc,
        },
      ],
    }
  },
})
