
import Vue, { PropType } from 'vue'
import SaLink from '~/components/_general/SaLink.vue'
import { foundationsHymnUrl, siteHymnUrl } from '~/assets/ts/utils/urls'
import { HymnResultsApi } from '~/apiclient/apihymns'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'

export default Vue.extend({
  name: 'HymnElement',
  components: { SaLink, LoadingElement, InlineIcon },
  props: {
    hymn: {
      type: Object as PropType<HymnResultsApi>,
      default: undefined,
    },
    isPsalm: {
      type: Boolean,
    },
    foundations: {
      type: Boolean,
    },
    tuneId: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    title(): string {
      if (!this.hymn) return ''
      return this.hymn.title
    },
    hasAudio(): boolean {
      if (!this.hymn) return false
      return this.hymn.hasAudio
    },
    hasHistory(): boolean {
      if (!this.hymn) return false
      return this.hymn.hasHistory
    },
    lyricist(): string {
      if (!this.hymn) return ''
      return this.hymn.lyricist
    },
    composer(): string {
      if (!this.hymn) return ''
      return this.hymn.composer
    },
    hymnID(): string {
      return this.hymn ? this.hymn.hymnID : ''
    },
    hymnLink(): string {
      if (!this.hymn) return ''
      if (this.foundations) {
        return foundationsHymnUrl(this, this.hymn, '', this.tuneId ?? '')
      }
      return siteHymnUrl(this.hymn, '', this.tuneId ?? '')
    },
  },
})
